<template>
  <PageNavbar />
  <v-main class="container mt-5">
    <div class="row mt-5 mx-3 mb-3">
      <div class="col-sm-7">
        <div class="card px-5 py-5">
          <div class="card-title">
            <h2>{{ title }}</h2>
          </div>

          <div v-if="!state.valid">
            <h5 class="mt-2">Unable to reset password.</h5>

            <p class="mt-2 mb-3">Password reset link is invalid. Please request a new one.</p>
            <router-link class="mt-3" to="/password/forgot">Back to Forgot Password</router-link>
          </div>
          <div v-else>
            <p class="mt-2 mb-3">Create a new password for your Speedify account.</p>
            <div>
              <form @submit.prevent="resetPassword">
                <div class="form-floating">
                  <input
                    id="emailInput"
                    type="email"
                    name="username"
                    readonly
                    class="form-control"
                    :value="state.email"
                    aria-label="Email Input"
                    aria-describedby="emailInput"
                  />
                  <label for="emailInput">Email</label>
                </div>

                <div class="form-floating my-3">
                  <input
                    id="newPasswordInput"
                    v-model="state.newPassword"
                    type="password"
                    name="password"
                    class="form-control"
                    placeholder="New Password"
                    aria-label="New Password"
                    aria-describedby="newPassword"
                  />
                  <label for="newPasswordInput">New Password</label>
                </div>
                <small v-if="$v.newPassword.required.$invalid" style="color: red"
                  >Password is required.</small
                >
                <small v-if="$v.newPassword.minLength.$invalid" style="color: red"
                  >Password must have at least 8 characters.</small
                >

                <div class="form-floating my-3">
                  <input
                    id="repeatPasswordInput"
                    v-model="state.repeatPassword"
                    type="password"
                    class="form-control"
                    placeholder="Repeat
            New Password"
                    aria-label="Repeat New Password"
                    aria-describedby="repeatNewPassword"
                  />
                  <label for="repeatPasswordInput">Repeat New Password</label>
                </div>
                <small v-if="$v.repeatPassword.sameAsNewPassword.$invalid" style="color: red"
                  >Passwords must be identical.</small
                >

                <!-- alerts for change password modal -->
                <div v-if="state.error" class="alert alert-danger my-3" role="alert">
                  <i class="fas fa-exclamation-circle"></i>&nbsp;Error: Unable to update password
                  <hr />
                  {{ state.errorContext }}
                </div>

                <button
                  type="button"
                  class="btn btn-speedify-blue mt-3"
                  :class="{
                    disabled: $v.newPassword.$invalid || $v.repeatPassword.$invalid || state.success
                  }"
                  @click="resetPassword"
                >
                  {{ title }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-main>
  <PageFooter />
</template>

<script setup lang="ts" charset="utf-8">
import { reactive, onMounted, computed } from 'vue';
import { useRoute, RouteParamValue } from 'vue-router';
import { required, minLength, sameAs } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import PageNavbar from '@/components/PageNavbar.vue';
import PageFooter from '@/components/PageFooter.vue';

const route = useRoute();

const title = route.query.firsttime === 'true' ? 'Set Password' : 'Reset Password';
const token = route.params.token as RouteParamValue;
const state = reactive({
  valid: true,
  email: '',
  newPassword: '',
  repeatPassword: '',
  success: false,
  error: false,
  errorContext: '',
});

const msg =
  'Something went wrong trying to request a password reset. Please try again later or contact support@speedify.com';

onMounted(async () => {
  try {
    const response = await fetch(`/api/password/reset/check-token/${token}`);
    if (response.status !== 200) {
      state.error = true;
      state.errorContext = msg;
    } else {
      const responseJson = await response.json();
      state.valid = responseJson.valid;
      state.email = responseJson.email;
    }
  } catch (err) {
    state.error = true;
    state.errorContext = (err as any).message || msg;
  }
});

const resetPassword = async () => {
  try {
    const response = await fetch(`/api/password/reset/${token}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: state.newPassword }),
    });
    if (response.status !== 200) {
      const responseJson = await response.json();
      state.error = true;
      state.errorContext = responseJson.message || msg;
    } else {
      state.success = true;
      // TODO: useRoute()
      window.location.replace('/login?passwordChanged=true');
    }
  } catch (err) {
    state.error = true;
    state.errorContext = (err as any).message || msg;
  }
};

const rules = {
  newPassword: {
    required,
    minLength: minLength(8),
  },
  repeatPassword: {
    required,
    sameAsNewPassword: sameAs(computed(() => state.newPassword)),
  },
};

const $v = useVuelidate(rules, state);
</script>
