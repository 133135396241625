<template>
  <div class="container justify-content-center text-center">
    <Message class="fw-normal" :severity="severity" :closable="false" pt:text:class="mx-auto">
      <template #icon>{{}}</template>
      <span>Your {{vendor}} “Powered By Speedify” router has been activated. </span>
      <small v-if="vendor">We have automatically added a complimentary 1TB Router license valid for 90 days of service to your account.</small>
    </Message>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import Message from 'primevue/message';
import { MessageProps } from 'primevue/message';


defineProps<{ vendor?: string }>();

const severity = ref<MessageProps['severity']>('info');
</script>

<style media="screen" scoped>
.p-message-info {
  background: #ECF7FD;
  outline-color: #A7DCF1;
  color: black;
}

.p-message-error {
  background: #fef3f2;
  outline-color: #f5c6cb;
  color: black;
}

.p-message .p-message-text span {
  font-weight: normal;
}

.p-message-text a {
  color: #00adee;
  text-decoration: none;
}
</style>
