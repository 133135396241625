import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// VLabs Components
import { VTimePicker } from 'vuetify/labs/VTimePicker';
import { VTreeview } from 'vuetify/labs/VTreeview';

import { SPEEDIFY_THEME, MIRI_THEME } from '@/theme/LightTheme';
import { brand } from '@/util';

export default createVuetify({
  components: {
    ...components,
    VTimePicker,
    VTreeview
  },
  directives,

  theme: {
    defaultTheme: brand === 'miri' ? 'MIRI_THEME' : 'SPEEDIFY_THEME',
    themes: {
      SPEEDIFY_THEME,
      MIRI_THEME,
    }
  },
  defaults: {
    VCard: {
      rounded: 'md'
    },
    VTextField: {
      variant: 'outlined',
      density: 'comfortable',
      color: 'primary'
    },
    VTextarea: {
      variant: 'outlined',
      density: 'comfortable',
      color: 'primary'
    },
    VSelect: {
      variant: 'outlined',
      density: 'comfortable',
      color: 'primary'
    },
    VListItem: {
      minHeight: '45px'
    },
    VTooltip: {
      location: 'top'
    }
  }
});
