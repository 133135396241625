import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

// {{{ builtin colorschemes
export const ORANGE_THEME: ThemeTypes = {
  name: 'ORANGE_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1,
  },
  colors: {
    primary: '#fb9678',
    secondary: '#03c9d7',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#f94d4d',
    lightprimary: '#fce6ed',
    lightsecondary: '#EDFBF7',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightwarning: '#FEF5E5',
    lightinfo:'#EBF3FE',
    textPrimary: '#11142d',
    textSecondary: '#777e89',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4'
  }
};


export const BLUE_THEME: ThemeTypes = {
  name: 'BLUE_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1,
  },
  colors: {
    primary: '#1e4db7',
    secondary: '#1a97f5',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#f94d4d',
    lightprimary: '#E8EDF8',
    lightsecondary: '#E8F4FE',
    lightsuccess: '#EBFAF2',
    lighterror: '#FDF3F5',
    lightwarning: '#FFF4E5',
    lightinfo:'#E6F4FF',
    textPrimary: '#11142d',
    textSecondary: '#777e89',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#F6F8FB',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4'
  }
};

export const AQUA_THEME: ThemeTypes = {
  name: 'AQUA_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1,
  },
  colors: {
    primary: '#0074BA',
    secondary: '#47D7BC',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#f94d4d',
    lightprimary: '#EFF9FF',
    lightsecondary: '#EDFBF7',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightinfo:'#EBF3FE',
    lightwarning: '#FEF5E5',
    textPrimary: '#11142d',
    textSecondary: '#777e89',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4'
  }
};

export const PURPLE_THEME: ThemeTypes = {
  name: 'PURPLE_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1,
  },
  colors: {
    primary: '#763EBD',
    secondary: '#95CFD5',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#f94d4d',
    lightprimary: '#F2ECF9',
    lightsecondary: '#EDF8FA',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightinfo:'#EBF3FE',
    lightwarning: '#FEF5E5',
    textPrimary: '#11142d',
    textSecondary: '#777e89',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4'
  }
};

export const GREEN_THEME: ThemeTypes = {
  name: 'GREEN_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1,
  },
  colors: {
    primary: '#0A7EA4',
    secondary: '#CCDA4E',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#f94d4d',
    lightprimary: '#F4F9FB',
    lightsecondary: '#FAFBEF',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightinfo:'#EBF3FE',
    lightwarning: '#FEF5E5',
    textPrimary: '#11142d',
    textSecondary: '#777e89',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4'
  }
};

export const RED_THEME: ThemeTypes = {
  name: 'RED_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1,
  },
  colors: {
    primary: '#5e244d',
    secondary: '#ff5c8e',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#f94d4d',
    lightprimary: '#EFE4EB',
    lightsecondary: '#FFF5F2',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightinfo:'#EBF3FE',
    lightwarning: '#FEF5E5',
    textPrimary: '#11142d',
    textSecondary: '#777e89',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4'
  }
};
// }}}

export const SPEEDIFY_THEME: ThemeTypes = {
  name: 'SPEEDIFY_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1,

    'theme-button-border-radius': '0.4rem',
    'theme-href-color': '#00B7FF',
    'theme-href-hover-color': '#1AC1FF',
    'theme-button-border-color': '#00ADEE',
    'theme-button-hover-color': '#1AC1FF',

    'theme-button-background-color': '#00B7FF',
    'theme-button-secondary-background-color': '#E4E7EC',
    'theme-button-on-secondary-color': '#000',
    'theme-button-outline-color': '#D0D5DD',

    'theme-on-surface': '#000',

    // 'theme-nav-background-color': --v-theme-background,
    // 'theme-card-border-color': 'none',
  },
  colors: {
    primary: '#1e4db7',
    secondary: '#1a97f5',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#f94d4d',
    lightprimary: '#E8EDF8',
    lightsecondary: '#E8F4FE',
    lightsuccess: '#EBFAF2',
    lighterror: '#FDF3F5',
    lightwarning: '#FFF4E5',
    lightinfo:'#E6F4FF',
    textPrimary: '#11142d',
    textSecondary: '#777e89',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#F6F8FB',
    hoverColor: '#f6f9fc',
    surface: '#f9f9f9',
    'on-surface': '#000',
    'on-surface-variant': '#fff',
    'on-primary': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4',
  }
};

export const MIRI_THEME: ThemeTypes = {
  name: 'MIRI_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1,

    'theme-button-border-radius': '0.4rem',
    'theme-input-background-color': '#fafafa',
    'theme-href-color': '#3062D4',
    'theme-href-hover-color': '#5880DA',
    'theme-button-border-color': 'transparent',
    'theme-button-hover-color': '#5880DA',
    'theme-button-secondary-background-color': 'white',
    'theme-button-on-secondary-color': '#5880DA',
    'theme-button-outline-color': '#5880DA',

    'theme-nav-background-color': '#3062D4',
    'theme-card-border-color': '#D4D9ED',
  },
  colors: {
    primary: '#1e4db7',
    secondary: '#1a97f5',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#f94d4d',
    lightprimary: '#E8EDF8',
    lightsecondary: '#E8F4FE',
    lightsuccess: '#EBFAF2',
    lighterror: '#FDF3F5',
    lightwarning: '#FFF4E5',
    lightinfo:'#E6F4FF',
    textPrimary: '#11142d',
    textSecondary: '#777e89',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#F6F8FB',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface': '#fff',
    'on-surface-variant': '#fff',
    'on-primary': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4',

    'button-background-color': '#3062D4',
    'button-tonal-background-color': '',
    'button-outline-color': '',
  },
};

// vi: foldmethod=marker:
