<template>
  <div v-if="subscription.upgradeDetails?.upgradeOptions?.length! > 0" class="row my-3 mb-5">
    <div id="accordionPanelsStayOpenExample" class="accordion rounded-3">
      <!-- individual upgrades -->
      <div v-if="upgradesAvailable.individual?.length > 0" class="accordion-item">
        <h2 id="panelsStayOpen-headingOne" class="accordion-header">
          <button
            type="button"
            class="accordion-button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            <div class="d-flex flex-wrap">
              <h3 class="col-sm-12">Speedify <span class="speedify-blue">for Individuals</span></h3>
              <small>Unlimited usage and premium server access</small>
            </div>
          </button>
        </h2>
        <!-- individual updgrade options and promotions -->
        <div
          id="panelsStayOpen-collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingOne"
          data-bs-parent="#accordionPanelsStayOpenExample"
        >
          <div class="accordion-body">
            <div class="row flex-wrap flex-lg-nowrap justify-content-around p-3">
              <div
                v-for="upgrade in upgradesAvailable.individual"
                :key="upgrade.planCode"
                class="col-lg mw-615 my-3"
              >
                <div
                  class="card text-center h-100 content-box upgrade-card"
                  :class="{
                    'individuals p-3': upgrade.promoted,
                    'card-grey m-2': !upgrade.promoted,
                  }"
                >
                  <NonMobileStoreUpgradePlanCard
                    :upgrade="upgrade"
                    @showUpgradeConfirmationModal="bubbleEventUp"
                  />
                </div>
              </div>
            </div>
            <div class="my-2 mt-4">
                Individual Plans Include:
            </div>
            <div class="">
              <ul>
                <li><strong>Unlimited Usage</strong> - Enjoy unlimited access</li>
                <li><strong>Premium Server Access</strong> - Access additional servers in more than 50 locations worldwide.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- families upgrades -->
      <div v-if="upgradesAvailable.families.length > 0" class="accordion-item">
        <h2 id="panelsStayOpen-headingTwo" class="accordion-header">
          <button
            type="button"
            class="accordion-button"
            :class="{ 'collapsed': upgradesAvailable.individual.length > 0 }"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseTwo"
            aria-expanded="false"
            aria-controls="panelsStayOpen-collapseTwo"
          >
            <div class="d-flex flex-wrap">
              <h3 class="col-sm-12">Speedify <span class="families-color">for Families</span></h3>
              <small>Unlimited usage, premium server access, plus user accounts for you and up to four other family members to share access</small>
            </div>
          </button>
        </h2>
        <!-- family updgrade options and promotions -->
        <div
          id="panelsStayOpen-collapseTwo"
          class="accordion-collapse collapse"
          :class="{ 'show': upgradesAvailable.individual.length === 0 }"
          aria-labelledby="panelsStayOpen-headingTwo"
          data-bs-parent="#accordionPanelsStayOpenExample"
        >
          <div class="accordion-body">
            <div class="row flex-wrap flex-lg-nowrap justify-content-around p-3">
              <div
                v-for="upgrade in upgradesAvailable.families"
                :key="upgrade.planCode"
                class="col-lg mw-615 my-3"
              >
                <div
                  class="card text-center h-100 content-box upgrade-card"
                  :class="{
                    'families': upgrade.promoted,
                    'p-3': upgrade.promoted,
                    'card-grey m-2': !upgrade.promoted,
                  }"
                >
                  <NonMobileStoreUpgradePlanCard
                    :upgrade="upgrade"
                    @showUpgradeConfirmationModal="bubbleEventUp"
                  />
                </div>
              </div>
            </div>
            <div class="my-2 mt-4">
                Family Plans Include:
            </div>
            <div class="">
              <ul>
                <li><strong>User Accounts for You and Four Family Members</strong> - Use each account on up to 5 devices simultaneously</li>
                <li><strong>Unlimited Usage</strong> - Enjoy unlimited access</li>
                <li><strong>Premium Server Access</strong> - Access additional servers in more than 50 locations worldwide.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- teams upgrades -->
      <div v-if="upgradesAvailable.teams.length > 0" class="accordion-item">
        <h2 id="panelsStayOpen-headingThree" class="accordion-header">
          <button
            type="button"
            class="accordion-button"
            :class="{ 'collapsed': upgradesAvailable.families.length > 0 }"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseThree"
            aria-expanded="false"
            aria-controls="panelsStayOpen-collapseThree"
          >
            <div class="d-flex flex-wrap">
              <h3 class="col-sm-12">Speedify <span class="teams-color">for Teams</span></h3>
              <small>Unlock enhanced account management, additional seats, dedicated servers, CLI, API, and more</small>
            </div>
          </button>
        </h2>
        <!-- teams upgrades options and promotions -->
        <div
          id="panelsStayOpen-collapseThree"
          class="accordion-collapse collapse"
          :class="{ 'show': upgradesAvailable.families.length === 0 }"
          aria-labelledby="panelsStayOpen-headingThree"
          data-bs-parent="#accordionPanelsStayOpenExample"
        >
          <div class="accordion-body">
            <div class="row flex-wrap flex-lg-nowrap justify-content-around p-3">
              <div
                v-for="upgrade in upgradesAvailable.teams"
                :key="upgrade.planCode"
                class="col-lg mw-615 my-3"
              >
                <div
                  class="card text-center h-100 content-box upgrade-card"
                  :class="{
                    'teams': upgrade.promoted,
                    'p-3': upgrade.promoted,
                    'card-grey m-2': !upgrade.promoted,
                  }"
                >
                  <NonMobileStoreUpgradePlanCard
                    :upgrade="upgrade"
                    @showUpgradeConfirmationModal="bubbleEventUp"
                  />
                </div>
              </div>
            </div>
            <div class="my-2 mt-4">
                Team Plans Include:
            </div>
            <div class="">
              <ul>
                <li>
                  <strong>Centralized Account Management</strong> - Add or remove seats as needed and use each on up to 5 devices simultaneously
                </li>
                <li>
                  <strong>Unlimited Usage</strong> - Enjoy unlimited access
                </li>
                <li>
                  <strong>Dedicated Server Add-Ons</strong> - Ability to purchase Dedicated Servers for consistent IPs, port forwarding, and more!
                </li>
                <li>
                  <strong>CLI &amp; API Access</strong> - Run Speedify from the command line or programmatically manage your Team
                  including adding or removing users, setting default configurations, and locking individual settings.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- router upgrades -->
      <div v-if="upgradesAvailable.routers?.length > 0" class="accordion-item">
        <h2 id="panelsStayOpen-headingOne" class="accordion-header">
          <button
            type="button"
            class="accordion-button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            <div class="d-flex flex-wrap">
              <h3 class="col-sm-12">Speedify <span class="speedify-blue">for Routers</span></h3>
              <small>Choose between 1 TB or 3 TB of recurring monthly usage, billed either monthly or yearly at a discount</small>
            </div>
          </button>
        </h2>
        <!-- router upgrade options and promotions -->
        <div
          id="panelsStayOpen-collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingOne"
          data-bs-parent="#accordionPanelsStayOpenExample"
        >
          <div class="accordion-body">
            <div class="row flex-wrap flex-lg-nowrap justify-content-around p-3">
              <div
                v-for="upgrade in upgradesAvailable.routers"
                :key="upgrade.planCode"
                class="col-lg mw-615 my-3"
              >
                <div
                  class="card text-center h-100 content-box upgrade-card"
                  :class="{
                    'individuals p-3': upgrade.promoted,
                    'card-grey m-2': !upgrade.promoted,
                  }"
                >
                  <NonMobileStoreUpgradePlanCard
                    :upgrade="upgrade"
                    @showUpgradeConfirmationModal="bubbleEventUp"
                  />
                </div>
              </div>
            </div>
            <div class="my-2 mt-4">
                Router Plans Include:
            </div>
            <div class="">
              <ul>
                <li>
                  <strong>
                    Activate your “Powered By Speedify” Router
                  </strong>
                  Run Speedify on your router and share the bonded super connection with all devices on your network
                </li>
                <li><strong>Premium Server Access </strong>Access additional servers in more than 50 locations worldwide</li>
                <li>
                  <strong>Dedicated Server Add-Ons </strong>
                  Ability to purchase Dedicated Servers for consistent IPs, port forwarding, and more!
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- dedi upgrades -->
      <div v-if="upgradesAvailable.servers?.length > 0" class="accordion-item">
        <h2 id="panelsStayOpen-headingOne" class="accordion-header">
          <button
            type="button"
            class="accordion-button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            <div class="d-flex flex-wrap">
              <h3 class="col-sm-12">Speedify <span class="speedify-blue"> Dedicated Servers</span></h3>
              <small>Upgrade to yearly billing and lock in our best rate!</small>
            </div>
          </button>
        </h2>
        <!-- dedi upgrade options and promotions -->
        <div
          id="panelsStayOpen-collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingOne"
          data-bs-parent="#accordionPanelsStayOpenExample"
        >
          <div class="accordion-body">
            <div class="row flex-wrap flex-lg-nowrap justify-content-around p-3">
              <div
                v-for="upgrade in upgradesAvailable.servers"
                :key="upgrade.planCode"
                class="col-lg mw-615 my-3"
              >
                <div
                  class="card text-center h-100 content-box upgrade-card"
                  :class="{
                    'individuals p-3': upgrade.promoted,
                    'card-grey m-2': !upgrade.promoted,
                  }"
                >
                  <NonMobileStoreUpgradePlanCard
                    :upgrade="upgrade"
                    @showUpgradeConfirmationModal="bubbleEventUp"
                  />
                </div>
              </div>
            </div>
            <div class="my-2 mt-4">
              Dedicated Server Plans Include:
            </div>
            <div class="">
              <ul>
                <li>Consistent IP address for those who need to whitelist their IP for certain secure services</li>
                <li>Port forwarding for those who need to run a server that can be reached from the Internet</li>
                <li>Guaranteed service level agreement - for each hour that your system is down, you get a day of free service</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="card-title">
    <div v-if="subscription.type === 'teams'" class="card">
      <div class="card-body py-4">
        Want to add additional seats or a dedicated server?
        <router-link
          :to="{ name: 'SubscriptionPortal', query: { referenceId: subscription.referenceId } }"
        >
          Click here to manage your Speedify Team
        </router-link>
      </div>
    </div>
    <div v-else-if="subscription.planCode.match(/nonrenew/i)" class="card">
      <div class="card-body py-4">
        You seem to be subscribed to the highest tier that we offer for non renewing subscriptions!
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body py-4">
        There doesn't seem to be any more upgrades available for your account.
        If you think this is an error, please contact our support team at support@speedify.com
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import NonMobileStoreUpgradePlanCard from './NonMobileStoreUpgradePlanCard.vue';
import { Subscription } from '@/model';

const props = defineProps<{ subscription: Subscription }>();
const emit = defineEmits(['showUpgradeConfirmationModal']);

const route = useRoute();
const { query } = route as any;

const upgradesAvailable = computed(() => {
  let upgradeOptions = [...props.subscription.upgradeDetails!.upgradeOptions];

  /*
    legacy upgrade shortcut support
    Compatibility with old subs-mgmt
    Filter upgrade options based on tier, period, or planCode
    This allows filtering products on the upgrades page
    More information: https://connectify.slack.com/archives/CNKVCR1NV/p1641399639022200
  */
  if (query.period) {
    upgradeOptions = upgradeOptions.filter((o) => o.planCode.toLowerCase().match(query.period.toLocaleLowerCase()));
  }
  if (query.plan) {
    upgradeOptions = upgradeOptions.filter((o) => o.planCode.toLocaleLowerCase().match(query.plan.toLocaleLowerCase()));
  }
  if (query.tier) {
    if (query.tier !== 'individual') {
      upgradeOptions = upgradeOptions.filter((o) => o.planCode.toLocaleLowerCase().match(query.tier.toLocaleLowerCase()));
    } else {
      // More info on ranks in SpeedifySubscriptionDetailsService
      upgradeOptions = upgradeOptions.filter((o) => o.rank < 4);
    }
  }
  // end legacy upgrade shortcut support

  const families = upgradeOptions
    .filter(u => u.planCode.match(/families/i))
    .toSorted((a, b) => b.rank - a.rank);
  const teams = upgradeOptions
    .filter(u => u.planCode.match(/teams/i))
    .toSorted((a, b) => b.rank - a.rank);
  const routers = upgradeOptions
    .filter(u => u.planCode.match(/router/i))
    .toSorted((a, b) => b.rank - a.rank);
  const servers = upgradeOptions
    .filter(u => u.planCode.match(/server/i))
    .toSorted((a, b) => b.rank - a.rank);
  const individual = upgradeOptions
    .filter(u => !u.planCode.match(/(teams|families|router|server)/i))
    .toSorted((a, b) => b.rank - a.rank);

  return {
    individual,
    families,
    teams,
    routers,
    servers,
  };
});

/**
 * We need to bubble this event up since vue doesn't allow
 * bubbling events. Since this seems like a one-off incident,
 * I am doing it like this and not setting up a store for it
 */
const bubbleEventUp = (elementID: string) => {
  emit('showUpgradeConfirmationModal', elementID);
};
</script>
