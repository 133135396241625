<template>
  <nav class="navbar navbar-expand-lg navbar-light py-3">
    <div class="container justify-content-center">
      <router-link class="navbar-brand logo-container pt-5" to="/" aria-describedby="speedify-logo-desc">
        <div id="speedify-logo-desc" style="display: none">Speedify home page</div>
        <SpeedifyLogo v-if="theme.name.value === 'SPEEDIFY_THEME'" />
        <img v-if="theme.name.value === 'MIRI_THEME'" src="@/assets/images/miri-speedify-logo.svg" />
      </router-link>
    </div>
  </nav>

  <v-main class="container-lg px-2 px-md-5">
    <div class="row mx-sm-3 mt-5 mx-0 mb-3 justify-content-center align-items-stretch">
      <div class="col-md-7 col-lg-6 col-xl-5 px-1 px-sm-2 mb-2 d-flex justify-content-center flex-wrap">
        <ClientComponent />

        <div class="justify-content-center">
          <h6 class="text-center mt-4">Need Help?</h6>
          <small>
            Contact us at
            <a href="mailto:support@speedify.com" class="speedify-blue">support@speedify.com</a>
          </small>
        </div>
      </div>
    </div>
  </v-main>
  <PageFooter />
</template>

<script setup lang="ts">
import { useTheme } from 'vuetify';

import SpeedifyLogo from '@/components/SpeedifyLogo.vue';
import PageFooter from '@/components/PageFooter.vue';
import ClientComponent from '@/components/welcome/ClientComponent.vue';

const theme = useTheme();
</script>
